import React from 'react';
import { DateInput, Edit, SimpleForm, TextInput,BooleanInput,NumberInput } from 'react-admin';

const EditUser = (props) => (
  <>
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="userCode" disabled />
      <TextInput source="createdAt" disabled />
      <TextInput source="hasSignedUp" disabled />
      <TextInput source="hasEnded" />
      <TextInput source="email" />
      <TextInput source="password" />
      <TextInput source="preWeddingLink" />
      <DateInput  source='appEndingDate'/>
      <BooleanInput source="groupSettings.enabled" label="Group Enabled" />
      <NumberInput source="groupSettings.numberOfGroups" label="Number of Groups"  max={4} min={1}/>
      <TextInput
        label="Invitation Link"
        source="inviteLink"
        defaultValue={props.code ? `https://aamantran.link/${props.code}` : ""}
        disabled
        fullWidth
      />
     {props.vari? <TextInput source="signUpCode" disabled />:<TextInput source="signUpCode"/>} 
      
    </SimpleForm>
  </Edit>
  </>
);

export default EditUser;