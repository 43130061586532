
import React,{useEffect} from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  DeleteWithConfirmButton,
  EditButton,
} from 'react-admin';
import { useDataProvider } from 'react-admin';
import UserSearchFilter from './UserSearchFilter';

const MyUsers = (props) => {
    
  // const dataProvider = useDataProvider();

  return (
    <List {...props} filters={<UserSearchFilter />}  title="User List">
      <Datagrid>
        <TextField source="_id" />
        <TextField source="userCode" label="User Code" />
        <TextField source="hasSignedUp" label="Has Signed Up" />
        <TextField source="hasEnded" label="Has Ended" />
2        <TextField source="signUpCode" label="signUpCode" />
        <TextField source="createdAt" label="Created At" />
        <TextField  source='appEndingDate' label="App Ending Date"/>
        <TextField   source='groupSettings.enabled'  label="Grouping"/>
        <EmailField source="email" />
        <EditButton basePath="/users" />
        <DeleteWithConfirmButton
            confirmContent="You will not be able to recover this record. Are you sure?"
            confirmColor="warning"
            />
      </Datagrid>
    </List>
  );
};

export default MyUsers;












