import './App.css';
import MyUsers from './MyUsers';
import { Admin, Resource } from 'react-admin';
import { fetchUtils } from 'react-admin';
import CreateUsers from './CreateUsers';
import EditUser from './EditUser';
import { authProvider } from './AuthProvider';
import { useState } from 'react';

const apiUrl = process.env.REACT_APP_BASEURL;
const httpClient = fetchUtils.fetchJson;

function App() {
  const [vari, setVari] = useState(false);
  const [code, setCode] = useState();

  const customDataProvider = (type, resource, params) => {
    let url = `${apiUrl}/${resource}`;
    let method = 'GET'; 

    switch (type) {
      case 'GET_LIST':
        if (params.filter && params.filter.userCode) {
          url = `${apiUrl}/userbycode/${params.filter.userCode}`;
        } else {
          url = `${apiUrl}/users`;
        }
        method = 'GET';

        return httpClient(url, { method, credentials: 'include' }).then(({ json }) => ({
          data: json.users.map(user => ({
            ...user,
            id: user._id,
          })),
          total: json.users.length,
        }));

      case 'CREATE':
        url = `${apiUrl}/user/create`;
        method = 'POST';
        return httpClient(url, { method, body: JSON.stringify(params.data), credentials: 'include' }).then(({ json }) => ({
          data: { ...params.data, id: json.userId },
        }));

      case 'DELETE':
        url = `${apiUrl}/user/${params.id}`;
        method = 'DELETE';
        return httpClient(url, { method, credentials: 'include' }).then(({ json }) => ({
          data: { id: params.id },
        }));

      case 'GET_ONE':
        url = `${apiUrl}/user/${params.id}`;
        method = 'GET';
        return httpClient(url, { method, credentials: 'include' }).then(({ json }) => {
          setVari(json.user.hasSignedUp);  // Set the value of vari here
          setCode(json.user.userCode)
          return {
            data: { ...json, id: json._id },
          };
        });

      case 'UPDATE':
        url = `${apiUrl}/user/${params.id}`;
        method = 'PUT';

        const updatedData = { ...params.data };
       
        return httpClient(`${apiUrl}/user/${params.id}`, { method: 'GET', credentials: 'include' })
          .then(({ json }) => {
            Object.keys(updatedData).forEach((field) => {
              if (updatedData[field] === json.user[field]) {
                delete updatedData[field];
              }
            });

            if (Object.keys(updatedData).length > 0) {
               console.log(updatedData)
              return httpClient(url, { method, body: JSON.stringify(updatedData), credentials: 'include' })
              .then(() => ({
                  data: { ...params.data, id: json.userId },
                }));
            }

            return { data: { ...params.data, id: json.userId } };
          });

      default:
        return Promise.reject(`Unsupported data provider type: ${type}`);
    }
  };

  return (
    <Admin authProvider={authProvider} dataProvider={customDataProvider}>
      <Resource name="users" list={MyUsers} create={CreateUsers} edit={EditUser({vari,code})} />
    </Admin>
  );
}

export default App;
